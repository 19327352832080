.map {
    /* width: 100vh; */
    height:100vh;
    margin: 0;
    padding: 0;
  }
  .map {
    background: #f8f4f0;
  }
  #basic-tool-container {
    display: table-cell;
    position: fixed;
    top: 90px;
    right: 15px;
    width: 50px;
    /* height: 550px; */
    z-index: 1;
    /* background: transparent; */
}
  #measurement-tool-container {
    display: table-cell;
    position: fixed;
    top: 90px;
    right: 15px;
    width: 50px;
    /* height: 550px; */
    z-index: 1;
    /* background: transparent; */
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.logo-panel{
  position: absolute;
  float:left;
  overflow: hidden;
  left: 5px;
  z-index: 1;
  bottom: 35px;
  color: whitesmoke;
  border-radius: 8px;
  display: flex;
  /* background-image: url(../img/logo.png); */
}
.box{
  position: absolute;
  float:left;
  overflow: hidden;

  z-index: 1;
  top: 10vh;
  color: whitesmoke;
  border-radius: 0 8px 8px 0;
  display: flex;
}
/* Add padding and border to inner content
for better animation effect */
.box-inner{
  width: 30vh;
  padding: 10px;
  background: #4e73dfc2;
  /* border: 1px solid #a29415; */
}



#layer-list-container {
  width: 10%;
  /* background-color: yellow; */
  height: 24px;
}
.btn-square {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;
  margin-top: -3px;
  background: #658bd5 !important;
  color: #fff;
  border-color: #6b93db !important;
}

#horizontal-list {
  /* min-width: 696px; */
  list-style: none;
  /* padding-top: 20px; */
}
#horizontal-list li {
  /* display: inline; */
  list-style: none;
}
.btn:active {
  outline: none !important;
  border: none !important;
  }
  
.btn:focus {outline:0 !important; box-shadow: none;}
*:focus {outline:none !important;box-shadow: none;}

label.checkbox {
  position: absolute;
  margin: -24px 0 0 -40px;
}
#layertree hr{
  background: #f8f9fc;
  margin: 10px 10px 20px -25px;
}

.ol-control button { 
  background-color: rgba(40, 40, 40, 0.8) !important;
}
.ol-control button:hover { 
  background-color: rgba(40, 40, 40, 1) !important;
}
.ol-geocoder .gcd-txt-control{
  height: 2.5em !important;
}
.ol-zoom{
  display: none;
}
input[type="range"], li>span{
  margin-left: -20px;
}
.custom-mouse-position {
  pointer-events: auto;
  /* color: red; */
  color: white;
  /* font-size: 20px; */
  /* font-weight: bolder; */
  background-color: rgb(117 144 226) !important;
  /* background-color: rgba(40, 40, 40, 0.8) !important; */
  
  padding: 2px 4px;
}
.ol-mouse-pos {
  /* margin-left: 85px; */
  /* padding: 1px 0.5em; */
  color: #000;
  text-shadow: 0 0 2px #fff;
  font-size: 12px;
  /* background: rgba(255,255,255,0.7); */
  z-index: 1;
  position: absolute;
  bottom: 1px;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-scale-line-inner {
  color: red;
  /* font-size: 20px; */
  background: white;
  margin-left: 100px;
  /* bottom: -5px; */
}
.ol-geocoder.gcd-txt-container {
  position: absolute;
  height: 0.0em !important;
  left: calc(50% - 9.5em) !important;
  width: 18em !important;
}

#currentLocation {
  display: none;
}
#currentLocation:checked + label {
  background: #28e430;
  border: #28e430;
}
.about-panel {
  position: absolute;
  float: right;
  overflow: hidden;
  right: 5px;
  z-index: 1;
  bottom: 35px;
  color: whitesmoke;
  border-radius: 8px;
  display: flex;
  margin: 0;
  padding: 3px 1.5em;
  color: #000;
  text-shadow: 0 0 2px #fff;
  font-size: 12px;
  background: rgba(255,255,255,0.8);
  font-size: 14px;
  font-weight: bolder;
}
.about-panel > a{
  text-decoration: none;
}

@media only screen and (max-device-width: 480px) {
  .about-panel {
    margin-bottom: 50px;
  }
  .logo-panel{
    bottom: 80px;
  }
  .ol-attribution.ol-unselectable.ol-control.ol-uncollapsible{
    margin-bottom: 55px;
  }
  .ol-mouse-pos {
    margin-bottom: 50px;
  }
  
}